<template lang="html">
    <div v-if="!emailConfirmed">
        邮件验证错误
    </div>
</template>

<script>
    export default {
        data() {
            return {
                emailConfirmed: null
            }
        },
        created() {
        },
        mounted() {
            this.confirmEmail()
        },
        destroyed() {
        },
        computed: {
        },
        methods: {
            confirmEmail() {
                const _this = this
                this.$axios.post('/Api/My/Users/ConfirmEmail', { userId: this.$route.query.userId, token: this.$route.query.token }).then((res) => {
                    if (res.data) {
                        this.confirmEmail = true
                        this.$message({
                            message: '验证成功',
                            type: 'info',
                            zIndex: 9999,
                        })
                        this.$router.push({ name: 'Profile' })
                    } else {
                        this.confirmEmail = false
                        this.$message({
                            message: '验证错误',
                            type: 'error',
                            zIndex: 9999,
                            //showClose: true
                        })
                    }
                }).catch((err) => {
                    this.confirmEmail = false
                    console.error(err)
                })
            },
        },
        components: {
        }
    }
</script>

<style lang="css">
</style>
